import { darken as originalDarken } from "polished"

export const alto = "#dddddd"
export const gallery = "#eeeeee"
export const goldenDream = "#f4db35"
export const nero = "#140600"
export const rouge = "#a74188"
export const silverSand = "#bfc1c2"
export const tundora = "#444444"

export const darken = color => originalDarken(0.1, color)

export const fontBody = "Open Sans"
export const fontHeading = "Open Sans"
export const fontHighlight = "Karla"

export const deviceSizes = {
  mobile: "(max-width: 480px)",
  tablet: "(max-width: 960px)",
}
