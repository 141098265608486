import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { ThemeProvider } from "styled-components"

import GlobalStyle from "$styled-components/GlobalStyle"
import * as theme from "$styled-components/theme"

import MobileProvider from "./MobileProvider"

const SITE_KEY = "6Lc5LcwUAAAAAGUWVaAac2vqY2nYVAqFji5cj9xk" // LNS site key
// const SITE_KEY = "6Ld4xd8UAAAAAFKgHa_CV0B7ijNXZf02liil5rdg" // localhost v3
// const SITE_KEY = "6Leuxd8UAAAAAK0Z6nLIU96cdQOKL6uht2Az2uus" // localhost v2 checkbox

const WrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <ThemeProvider theme={theme}>
        <MobileProvider>
          <GlobalStyle />
          {element}
        </MobileProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  )
}

export default WrapRootElement
